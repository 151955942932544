// websocket实时通讯
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 发送消息
export const SendMessage= (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/send`,
    method: 'post',
    data: params,
  })
}
//左侧好友列表
export const SearchItem = (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/findByName`,
    method: 'get',
    params:params,
  })
}
//右侧消息列表
export const DialogueContent = (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/findId`,
    method: 'get',
    params:params,
  })

}

//总的未读消息
export const UnreadMessage = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/online/totalUnread`),
    method: 'get',
    params: params,
  })
}
//分享项目
export const FarmerSharing = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/online/findProject`),
    method: 'get',
    params: params,
  })
}
// 分享竞价
export const jingjia = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/conversation/selectXmxxAndOrder`),
    method: 'get',
    params: params,
  })
}

//交换电话
export const exchangeTelephone = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/conversation/agree`),
    method: 'post',
    params: params,
  })
}
export const conversationMachineryAgricultural = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/agricultural/conversationMachineryAgricultural`),
    method: 'post',
    params: params,
  })
}

//是否接受托管或接管
export const Hosting = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/order/isAgree`),
    method: 'post',
    params: params,
  })
}


// 在合同页面来确定他是否已经沟通过 -------------废弃
// export const selectCompany = (params) => {
//   return service({
//     url:(`${baseUrl}/trade-website/order/selectCompany`),
//     method: 'get',
//     params: params,
//   })
// }

// 在合同页面来确定他是否已经沟通过
export const selectCompany = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/order/pdf`),
    method: 'get',
    params: params,
  })
}


// 根据orderid来查找数据
export const orderByid = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/order/orderByid`),
    method: 'get',
    params: params,
  })
}


// 接管单详情

export const companyByid = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/order/companyByid`),
    method: 'get',
    params: params,
  })
}


// 查看申请
export const Agree = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/tw-trade-xmxx-agree/agree`),
    method: 'post',
    params: params,
  })
}


// 查看申请
export const agreeRecirculatio = (params) => {
  return service({
    url:(`${baseUrl}/trade-website/tw-trade-xmxx-agree/agreeRecirculatio`),
    method: 'post',
    params: params,
  })
}
//流转审批-系统提示

export const selectPrompted = () => {
  return service({
    url:(`${baseUrl}/trade-website/cqjy/selectPrompted`),
    method: 'get',

  })
}
