<template>
  <div id="app">
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0"> -->
    <el-dialog
    title="系统提示"
    width="30%"
    center
    :visible.sync="TipsShow"
    :before-close="handleClose">
    <div class="TipsBox">
      <div class="TipsContent" style="font-size:20px" >
        {{TipsList.name}}您好，您本年度已累计流转土地{{TipsList.totalArea}}亩，依据
        <a  @click="policy" style="color:#3434FF; font-weight: 800; text-decoration: underline;">《{{TipsList.articleTitle}} 》</a>
        规定，您需要将流转合同提交有关部门审批，请问是否现在提交审批？
      </div>
      <div class="TipsBtn">
          <button class="closeBtn" @click="closeBtn">待流转计划完成后统一流转</button>
          <button class="ToPathBtn" @click="goToSubmit">去提交</button>
      </div>
    </div>
    </el-dialog>
    <my-header/>

    <router-view ></router-view>
    <my-footer/>
  </div>
</template>
<script>
import myHeader from "./components/header/index";
import myFooter from "./components/footer/index";
import { mapGetters,mapMutations } from "vuex";
import {selectPrompted} from './api/websocket'
import { getUserMenuByIdentity } from "@/api/add";

export default {
  components: {
    myHeader,
    myFooter,
  },
  data: () => ({
    TipsList:{},
    TipsShow:false,
    baseUrl:window.globalUrl.BASE_API
  }),
  watch: {
    '$route' (to, from) {
      // 当路由变化时调用接口
      console.log(to.path,'查看路由的监听')
      this.isTipsShow()
    }
  },
mounted(){

},
  created(){
    // this.selectPrompted()
    // this.TipsShow=this.SetTipsShow
       // 登录

      //  console.log(!this.foreverNoShow,'查看这里的值1122221')

  },
  computed: {
    ...mapGetters(["identityObj","vipLeftTree","userInfo",]),

  },

  methods:{
  //系统提示调用接口
  isTipsShow(){
    if (this.identityObj.identityTypeCode) {

//判断是否此登录期间都不显示

let hasShowTip= sessionStorage.getItem("hasShowTip");
let isUrl=sessionStorage.getItem('isUrl')
if(isUrl){
  sessionStorage.setItem('hasShowTip','')
}
  if(!hasShowTip){
         this.selectPrompted()//系统提示
        //  localStorage.setItem("hasShowTip",true)
  }
}
  },

    policy(){
       window.open(`${this.baseUrl}#/home/news?c=${this.TipsList.articleContent }`, '_blank'); // 在新窗口中打开链接
      //  window.open('http://localhost:8086/#/home/news?c=1f7c8e8b8aeb67cd5fb69f256c0d630d', '_blank'); // 在新窗口中打开链接
      //  window.open('http://localhost:8086/#/home/news?c=9f17009d69a5a360cb2ce048c9ccb7ab', '_blank'); // 在新窗口中打开链接
      // this.$router.push({ name: "newsPage", query: { c: this.TipsList.articleContent } });
      // this.SET_TOROUTER(true)

      sessionStorage.setItem("hasShowTip",true)
      sessionStorage.setItem('isUrl',true)
    },
      //系统提示
   async selectPrompted(){
        let res= await selectPrompted()

      if(res.code==0){
        this.TipsList=res.data
        this.TipsShow=true
      }
    },
    handleClose(){
      this.TipsShow=false
      sessionStorage.setItem("hasShowTip",true)

    },
    closeBtn(){
      this.TipsShow=false
      sessionStorage.setItem("hasShowTip",true)


    },
    goToSubmit(){
      this.TipsShow=false
      sessionStorage.setItem("hasShowTip",true)

      this.toPath()
    },
    //跳转的菜单
    toPath(){
        // 会员中心
        let arr = this.vipLeftTree;
        console.log("arr", arr);
        if (!this.userInfo.identityList) {
          this.$router.push({ name: "login" });
        } else {
          if (this.identityObj.identityName && arr.length == 0) {
            this.getOthersInfo();
          }
          console.log(arr,'查看arr')
          if (arr.length > 0) {
            if (arr[1].children.length == 0) {
              this.$router.push({
                path: arr[1].allPath,
              });
            } else {
              if (arr[1].children.length > 0) {
                this.$router.push({
                  path: arr[1].children[3].allPath,
                });
              }
            }
          }
        }
    },
    async getOthersInfo() {
      try {
        let res = await getUserMenuByIdentity();
        // console.log("121241456", res);
        if (res.code == 0 && res.data.menuList.length > 0) {
          res.data.menuList.forEach((item) => {
            if (item.path === "memberCenter") {
              let arr = this.transMenu([item][0].children, "/memberCenter");
              console.log("1", arr);
              if (arr.length > 0) {
                this.setVipTree(arr);
                this.$router.$avueRouter.formatRoutes(arr, true);
                if (arr[0].children.length == 0) {
                  this.$router.push({
                    path: arr[0].allPath,
                  });
                } else {
                  if (arr[0].children.length > 0) {
                    this.$router.push({
                      path: arr[0].children[0].allPath,
                    });
                  }
                }
              }
            }
          });
        }
      } catch (error) {
        console.log("错误", error);
      }
    },
  }
};
</script>
<style lang="less">

.TipsBox {
  width:100%;
  height: auto; /* 高度可以根据内容自动调整 */
  font-size: 16px; /* 基本的字体大小适用于 PC */
  display: flex;
  flex-direction: column;
  .TipsBtn{
    display: flex;
    margin-top:50px;
   justify-content: space-between;
  .closeBtn  {
     padding: 15px,10px;
     font-size: 23px;
      background: #d6d6d6;
      color: white;
      border-radius: 5px;
      border: none;
    }
    .ToPathBtn{
      padding: 15px,10px;
      font-size: 23px;
      background: #0A9F1A;
      color: white;
      border-radius: 5px;
      border: none;
    }

  }
}
.el-dialog__header{
  background-color: white !important;
}
</style>
