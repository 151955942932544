// 新版本 的接口
import service from '../request/index'
const baseUrl = window.globalUrl.HOME_API;

// 新版本-获取土地类型
export const getLandType = (data) => {
  return service({
    url: `${baseUrl}/trade-website/order/getLandType`,
    method: 'get',
    params: data
  })
}

// 修改登录手机号
export const changeLoginPhone = (data) => {
  return service({
    url: `${baseUrl}/admin/user/modifyLoginPhone`,
    method: 'put',
    params: data
  })
}
// 修改联系人
export const changeContract = (data) => {
  return service({
    url: `${baseUrl}/admin/user/updateContact`,
    method: 'post',
    params: data
  })
}
//修改用户资料
export const changeUserInfo = (data) => {
  return service({
    url: `${baseUrl}/admin/user/modifyIdentity`,
    method: 'post',
    data: data
  })
}


// 新版本-根据id获取种植作物和托管环节
export const getCropContent = (id) => {
  return service({
    url: `${baseUrl}/trade-website/order/getCropContent?id=` + id,
    method: 'get',
  })
}
// 农服商获取全部种植作物
export const getCompanyCrop = (data) => {
  return service({
    url: `${baseUrl}/trade-website/company/getCompanyCrop`,
    method: 'get',
    params: data
  })
}
// 托管种养品种
export const getBreedInTrust = (data) => {
  return service({
    url: `${baseUrl}/trade-website/company/getDisticntCompanyCrop`,
    method: 'get',
    params: data
  })
}
// 新行政区接口
export const getXzqArr = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/area/areaTree`,
    params: params
  })
}

export const selectListById = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/dept/selectListById`,
    params: params
  })
}

// 托管管理-推送
export const orderPush = (params) => {
  return service({
    method: 'put',
    url: `${baseUrl}/trade-website/order/OrderPush`,
    data: params
  })
}
// 托管管理-接管推送列表
export const getCompanyListToPage = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/company/getCompanyListToPage`,
    params: params
  })
}
//信息发布-分类
export const getMessageType = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/cms/catalog/select`,
    params: params
  })
}
//信息发布-分页
export const getNewsList = (params) => {
  return service({
    method: 'POST',
    url: `${baseUrl}/admin/cms/getArticleByPage`,
    data: params
  })
}
//托管数据统计
export const tgsjCountData = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/countData`,
    params: params
  })
}
//删除 我要托管
export const removeDetail = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/order/removeDetail`,
    params: params
  })
}

//获取当前用户已认证列表
export const getIdentityList = () => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/user/identityList`,
  })
}
//获取当前用户信息
export const getUserNow = () => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/user/userNow`,
  })
}

//获取当前登录用户权限及身份信息
export const getUserMenuByIdentity = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/user/userMenuByIdentity`,
    params: params
  })
}

//站内信息-分页
export const getStaionPagelist = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/message/page`,
    data: params
  })
}
//发布通知公告-新增
export const postNewsObj = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/admin/cms/publish`,
    data: params
  })
}
//收藏
export const collectionOrder = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/order/collectionOrder`,
    params: params
  })
}

//取消收藏
export const removeCollection = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/company/removeCollection`,
    params: params
  })
}
// 农户收藏服务商
export const collectionLand = (params) => {
  return service.post(`${baseUrl}/trade-website/order/collectionLand`, params)
  // return service({
  //   method: 'post',
  //   url: `${baseUrl}/trade-website/order/collectionLand`,
  //   params: params
  // })
}


//农户取消收藏服务商
export const updateCollection = (params) => {
  // return service.post(`${baseUrl}/trade-website/order/updateCollection`, params)
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/order/updateCollection`,
    params: params
  })
}


//删除新闻
export const removeNews = (params) => {
  return service({
    method: 'delete',
    url: `${baseUrl}/admin/cms/delete`,
    params: params
  })
}

//发布通知公告-新增
export const getPushList = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/orderPushToPage`,
    params: params
  })
}

//展示本行政区农户信息
export const getOrderCount = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/getOrderCount`,
    params: params
  })
}
//文件下载
export const download = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/file/get`,
    params: params,
    responseType: 'blob'
  })
}

//农服商导出合同
export const companyPusgPdf = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/company/companyPusgPdf`,
    params: params,
    responseType: "blob"
  })
}

export const companyPusgPdfNoBlob = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/company/companyPusgPdf`,
    data: params,
  })
}

//农户导出合同
export const orderPushPdf = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/orderPushPdf`,
    params: params,
    responseType: "blob"
  })
}

//展示农户所有为发布地块信息
export const getOrderNotPush = () => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/getOrderNotPush`,
  })
}

//我要接管-删除
export const deleteCompany = (id) => {
  return service({
    method: 'delete',
    url: `${baseUrl}/trade-website/company/` + id,
  })
}

//菜单目录树
export const getTreeByParentId = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/menu/tree`,
    params: data
  })
}

//融资上传图片
export const uploadPhoto5 = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/financing/uploadPhoto`,
    data: data
  })
}
//更新订单状态和推送消息
export const updateOrderState = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/order/updateOrderState`,
    params: data
  })
}

//更新农服商状态和消息推送
export const updateCompanyState = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/company/updateCompanyState`,
    params: data
  })
}
//更新接管
export const updateCompany = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/text/updateCompany`,
    data: data
  })
}
//新版本 停用/启用农服商
export const updateStatus = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/company/updateStatus`,
    data: data
  })
}

//统计-资源资产流转，成交数据top N
export const statLzDataTopN = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/cqjy/statLzDataTopN1`,
    params: params
  })
}

//统计-分页资源资产流转数据
export const getStatLzData = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/cqjy/statLzDataPg`,
    params: params
  })
}

//导出统计结果
export const exportLzData = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/cqjy/exportLzData`,
    params: params,
    responseType: "blob",
  })
}

//新版本-土地托管数据统计 农户宗数
export const getCountTrustShip = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/getCountTrustShip`,
    params: params,
  })
}
//新版本-农服商获取全部农事类型
export const getCompanyScopeAll = () => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/company/getCompanyScopeAll`,
  })
}
//新版本-农服商详情通过id
export const getCompanyById1 = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/company/getCompanyById`,
    params: data
  })
}
//新版本-订单详情
export const getDetail2 = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/getDetail`,
    params: data
  })
}

//新版本-农服商获取全部作物
export const getDisticntCompanyCrop = () => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/company/getDisticntCompanyCrop`,
  })
}

//公司导出合同 优化版本
export const updateReadFlag = (data) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/message/updateReadFlag`,
    params: data
  })
}


//公司导出合同 优化版本
export const getSupplys = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/getSupply`,
    params: data
  })
}

//公司导出合同 优化版本
export const selectCompany = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/selectCompany`,
    params: data
  })
}

//上传合同照片 优化版本
export const uploadContract = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/cqjy/uploadContract`,
    data: params
  })
}
//查看合同 优化版本
export const selectContractPic = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/cqjy/selectContractPic`,
    params: params
  })
}
// 查看本村已成交的项目信息
export const selectCjTime = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/cqjy/selectCjTime`,
    params: params
  })
}
//合同备案列表
export const selectFilingNumPic = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/cqjy/selectFilingNumPic`,
    params: params
  })
}
// 订单详情列表

export const selectLandDetail = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/selectLandDetail`,
    params: params
  })
}
